import * as React from "react"
import { Frame, addPropertyControls, ControlType } from "framer"

export function CustomButton(props) {
    const handleClick = () => {
        const urlParams = new URLSearchParams(window.location.search)
        const redirectUrl =
            props.link +
            (props.link.includes("?") ? "&" : "?") +
            urlParams.toString()
        window.open(redirectUrl, "_blank")
    }

    return (
        <Frame
            background={props.background}
            borderRadius={props.borderRadius}
            padding={props.padding}
            onTap={handleClick}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {props.icon && (
                    <img src={props.icon} style={{ marginRight: "8px" }} />
                )}
                <span
                    style={{ color: props.textColor, fontSize: props.fontSize }}
                >
                    {props.text}
                </span>
            </div>
        </Frame>
    )
}

addPropertyControls(CustomButton, {
    text: {
        title: "Text",
        type: ControlType.String,
        defaultValue: "Button",
    },
    textColor: {
        title: "Text Color",
        type: ControlType.Color,
        defaultValue: "white",
    },
    fontSize: {
        title: "Font Size",
        type: ControlType.Number,
        defaultValue: 16,
        unit: "px",
    },
    background: {
        title: "Background",
        type: ControlType.Color,
        defaultValue: "#007AFF",
    },
    borderRadius: {
        title: "Border Radius",
        type: ControlType.Number,
        defaultValue: 8,
        unit: "px",
    },
    padding: {
        title: "Padding",
        type: ControlType.FusedNumber,
        defaultValue: 12,
        unit: "px",
    },
    icon: {
        title: "Icon",
        type: ControlType.Image,
    },
    link: {
        title: "Link",
        type: ControlType.String,
        defaultValue: "/",
    },
})
